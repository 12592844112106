import React, {useState, useEffect} from "react"
import { Link } from 'gatsby'


import Layout from '../components/layout'
import Image from '../components/image'
import Ionicon from 'react-ionicons'
import MenuButton from '../components/menuButton'
import ReactTypingEffect from 'react-typing-effect';
import Particles from 'react-particles-js';
import CustomParticles from '../components/CustomParticles.js';
import SantaCSS from '../components/christmas/Santa.css'
import Lights from '../components/christmas/Lights.css'
import Gift1src from '../images/jul/gift1.svg'
import Gift2src from '../images/jul/gift2.svg'
import Gift3src from '../images/jul/gift3.svg'
import Gift4src from '../images/jul/gift4.svg'
import Gift5src from '../images/jul/gift5.svg'
import TreeSrc from '../images/jul/tree.svg'
import BagSrc from '../images/jul/bag1.svg'
import SleighSrc from '../images/jul/Sleigh.svg'
import styled from 'styled-components'


const Content = styled.div`
@import url('https://fonts.googleapis.com/css2?family=Cookie&display=swap');
`

const Title = styled.div`
  font-family:Cookie;
  font-size:78pt;
  text-align:center;
  padding-top:36px;
`

const Subtitle = styled.div`
  position:absolute;
  font-family:Cookie;
  text-align:center;
  font-size:40pt;
  width:100%;
`
const TopRightLights = styled.div`
  position:absolute;
  height: 200px;
  width: 100%;
  transform: rotate(45deg);
  left: 50%;
`
const TopLeftLights = styled.div`
  position:absolute;
  height: 200px;
  width: 100%;
  transform: rotate(-45deg);
  right: 50%;
`

const Tree = styled.div`
  position:absolute;
  background:url(${TreeSrc});
  height: 600px;
  width: 430px;
  right: -8%;
  bottom: 0%;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility:    hidden;
  -ms-backface-visibility:     hidden;

`


const Gift1 = styled.div`
  position:absolute;
  background:url(${Gift1src});
  height: 110px;
  width: 110px;
  right: 5%;
  bottom: -2%;
  background-repeat: no-repeat;
  z-index:3;
  cursor: pointer;
  &:hover{
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  @keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`

const Gift2 = styled.div`
  position:absolute;
  background:url(${Gift2src});
  height: 100px;
  width: 100px;
  right: 0%;
  bottom: 0%;
  background-repeat: no-repeat;
  z-index:1;
  cursor: pointer;
  &:hover{
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  @keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`

const Gift3 = styled.div`
  position:absolute;
  background:url(${Gift3src});
  height: 110px;
  width: 110px;
  left: 21%;
  bottom: -3%;
  background-repeat: no-repeat;
  z-index:2;

  cursor: pointer;
  &:hover{
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  @keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }

`

const Gift4 = styled.div`
  position:absolute;
  background:url(${Gift4src});
  height: 110px;
  width: 110px;
  left: 27%;
  bottom: 0%;
  background-repeat: no-repeat;
  z-index:0;

  cursor: pointer;
  &:hover{
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  @keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`


const Gift5 = styled.div`
  position:absolute;
  background:url(${Gift5src});
  height: 110px;
  width: 110px;
  right: 10%;
  bottom: -2%;
  background-repeat: no-repeat;
  z-index:1;

  cursor: pointer;
  &:hover{
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }
  @keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`
const Bag = styled.div`
  position:absolute;
  background:url(${BagSrc});
  height: 240px;
  width: 250px;
  left: 10%;
  bottom: -1%;
  background-repeat: no-repeat;
  z-index:1;
  transform:scaleX(-1);
`
const Sleigh = styled.div`
  position:absolute;
  background:url(${SleighSrc});
  height: 260px;
  width:786px;
  left: -393px;
  bottom: -1%;
  background-repeat: no-repeat;
  z-index:0;
  object-fit: contain;
`
const SantaContainer = styled.div`
  opacity:${props=>props.isShown ? 1 : 0}
  transition: opacity 1s;
`

const VideoContainer = styled.div`
  opacity:${props=>props.isShown ? 0 : 1}
  pointer-events:${props=>props.isShown ? "none" : "auto"}
  transition: opacity 0.9s;
  background-color: blue;
  position: absolute;
  top: 23%;
  left: 50%;
  margin-left: -280px;
  width: 560px;
  height: 315px;
`


//Class
const Godjul = (props => {
  //const [isGift, setGift] = useState(false);

  const Santa = () =>{
    return (<div className="window">
		<div className="santa">
			<div className="head">
				<div className="face">
					<div className="redhat">
						<div className="whitepart"></div>
						<div className="redpart"></div>
						<div className="hatball"></div>
					</div>
					<div className="eyes"></div>
					<div className="beard">
						<div className="nouse"></div>
						<div className="mouth"></div>
					</div>
				</div>
				<div className="ears"></div>
			</div>
			<div className="body"></div>
		</div>
	</div>
)
  }

  const Lights = () => {

    return (
      <div class="light">
       <ul class="line">
           <li class="red"></li>
           <li class="yellow"></li>
           <li class="blue"></li>
           <li class="pink"></li>
           <li class="red"></li>
           <li class="green"></li>
           <li class="blue"></li>
           <li class="yellow"></li>
           <li class="red"></li>
           <li class="pink"></li>
           <li class="blue"></li>
           <li class="yellow"></li>
           <li class="red"></li>
           <li class="green"></li>
           <li class="blue"></li>
           <li class="yellow"></li>
           <li class="red"></li>
           <li class="pink"></li>
           <li class="green"></li>
           <li class="blue"></li>
           <li class="pink"></li>
           <li class="red"></li>
           <li class="green"></li>
           <li class="blue"></li>
       </ul>
   </div>

    )
  }

  const From = () => {
    return(<div className="message">
      <h1 className="jul">Erick & Linn!</h1>
      <p className="jul">Click on the Gifts to find yours</p>
    </div>)
  }

  const [isGift, setIsGift] = useState(false)


  useEffect(() => {
      if (isGift === true){
        console.log("Is the gift!");

      }
    },[isGift]);

    useEffect(() => {
        document.body.style.backgroundColor = "#de2f32"
      });

  return (
    <Content>
      <Title>God Jul!</Title>
      <SantaContainer isShown={!isGift}>
        <Santa/>
      </SantaContainer>

      <TopRightLights><Lights/></TopRightLights>
      <TopLeftLights><Lights/></TopLeftLights>
      <From/>
      <Gift1 onClick={() => setIsGift(false)}/>
      <Gift2 onClick={() => setIsGift(false)}/>
      <Gift3 onClick={() => setIsGift(true)}/>
      <Gift4 onClick={() => setIsGift(false)}/>
      <Gift5 onClick={() => setIsGift(false)}/>
      <Tree/>
      <Bag/>
      <Sleigh/>
      <VideoContainer isShown={!isGift}>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/KVD_-I0Ec2k" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </VideoContainer>

    </Content>
  )
})

export default Godjul
